import { useState, useEffect } from 'react';
const useMobileDetection = () => {
   const [isMobile, setIsMobile] = useState(false);
   
   useEffect(() => {
      function handleResize() {
         if (window.innerWidth <= 760) {
            setIsMobile(true);
         } else {
            setIsMobile(false);
         }
      }
      
      handleResize();
      window.addEventListener('resize', handleResize);
      
      return () => {
         window.removeEventListener('resize', handleResize);
      };
   }, []);
   
   return isMobile;
}

export default useMobileDetection
