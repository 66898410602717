import React, { useState, useEffect } from "react";
import PortfolioData from "../../data/portfolio/PortfolioData.json";
import PortfolioItem from "./PortfolioItem";
import { FaSpinner } from "react-icons/fa";

const filters = [
    {
      id: 1,
      text: "Featured",
    },
    {
      id: 2,
      text: "All",
    },
   {
      id: 3,
      text: "AI Tools",
   },
    {
      id: 4,
      text: "Content Creation Tools",
    },
    {
      id: 5,
      text: "Customer Communication Tools",
    },
    {
      id: 6,
      text: "Developer Tools",
    },
    {
      id: 7,
      text: "Non-Profit",
    }
];



const alldata = PortfolioData;
const PortfolioOne = ({ Column }) => {
    const [getAllItems] = useState(alldata);
    const [dataVisibleCount, setDataVisibleCount] = useState(18);
    const [dataIncrement] = useState(18) ;
    const [noMorePost, setNoMorePost] = useState(false);
    const [activeFilter, setActiveFilter] = useState("featured");
    const [visibleItems, setVisibleItems] = useState([]);
   
   const filterItems = (filterCategory) => {
      setActiveFilter(filterCategory);
      let tempData;
      switch (filterCategory) {
         case 'featured':
            tempData = getAllItems.filter((data) => data.featured);
            break;
         case 'all':
            tempData = getAllItems;
            break;
         default:
            tempData = getAllItems.filter(
               (data) => {
                  return filterCategory === filters[0].text.toLowerCase() ?
                     data.id <= dataVisibleCount :
                     data.category.toLowerCase() === filterCategory && data.id <= dataVisibleCount;
               }
            );
      }
      setVisibleItems(tempData);
   };

   useEffect(() => {
      const filterCategory = filters[0].text.toLowerCase();
      filterItems(filterCategory);
   }, []);

   const handleChange = (e) => {
      e.preventDefault();
      const filterCategory = e.target.textContent.toLowerCase();
      filterItems(filterCategory);
   };
   
   
   const handleLoadmore = (e) => {
        e.preventDefault();
        let tempCount = dataVisibleCount + dataIncrement;
        if (dataVisibleCount >= getAllItems.length) {
            setNoMorePost(true);
        } else {
            setDataVisibleCount(tempCount);
            if (activeFilter === filters[0].text.toLowerCase()) {
                setVisibleItems(getAllItems.filter((data) => data.id <= tempCount));
            }else {
                setVisibleItems(
                    getAllItems.filter(
                      (data) => data.category === activeFilter && data.id <= tempCount
                    )
                );
            }
        }
    }
    
    return (
        <>
            <div className="row row--15">
                <div className="col-lg-12">
                    <ul className="rwt-portfolio-filter filter-button-default liststyle mb--20">
                        {filters.map((filter) => (
                        <li className="list-item" key={filter.id}>
                            <button
                            onClick={handleChange}
                            className={
                                filter.text.toLowerCase() === activeFilter
                                ? "current"
                                : " "
                            }
                            >
                            {filter.text}
                            </button>
                        </li>
                        ))}
                    </ul>
                </div>
            </div>

            <div className="row row--15" style={{marginBottom:200}}>
                {visibleItems.map((item) => (
                    <div key={item.id} className={Column}>
                        <PortfolioItem portfolio={item} />
                    </div>
                ))}
            </div>
{/*
            <div className="row row--15">
                <div className="col-lg-12">
                    <div className="rwt-load-more text-center mt--50">
                        <button
                            className="btn btn-default btn-large btn-icon"
                            onClick={handleLoadmore}
                            disabled={noMorePost ? "disabled" : null}
                        >
                            {noMorePost ? (
                            "No Item Here"
                            ) : (
                            <span>
                                Load More
                                <span className="icon">
                                    <FaSpinner />
                                </span>
                            </span>
                            )}
                        </button>
                    </div>
                </div>
            </div>*/}
        </>
    )
}

export default PortfolioOne;
