import React from 'react';
import { FiFacebook, FiTwitter, FiInstagram, FiLinkedin, FiChevronRight, FiMapPin, FiPhone } from "react-icons/fi";
//import { Link } from 'react-router-dom'

const HeaderTopBar = () => {
    return (
        <div className="header-top-bar">
            <div className="container">
                <div className="row align-items-center">
                    <div className="col-lg-4 col-md-12 col-12">
                        <div className="header-left">
                            <p><a href="#link">Formulating the Future <FiChevronRight /></a></p>
                        </div>
                    </div>
                    <div className="col-lg-8 col-md-12 col-12">
                        <div className="header-right">
                            <div className="address-content">
                                <p><FiMapPin /><span>Florida, USA</span></p>
                                <p><FiPhone /><span><a href="#">1-844-262-4828</a></span></p>
                            </div>
                            <div className="social-icon-wrapper">
                                <ul className="social-icon social-default icon-naked">
                                    <li><a target="_blank" href="https://facebook.com/sendboth" rel="noreferrer"><FiFacebook /></a></li>
                                    <li><a target="_blank" href="https://twitter.com/formulatelab" rel="noreferrer"><FiTwitter /></a></li>
                                    <li><a target="_blank" href="https://instagram.com/sendboth" rel="noreferrer"><FiInstagram /></a></li>
                                    <li><a target="_blank" href="https://linkedin.com/sendboth" rel="noreferrer"><FiLinkedin /></a></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default HeaderTopBar;
